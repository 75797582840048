<template>
<div :class="'tgju-widgets-row dashboard-widgets-technical-list dash-full-height ' + (is_grid_view ? 'grid-view-items' : '')">
    
    <div class="mobile-app-tabs" v-if="is_home">
        <router-link v-for="(item, index) in static_market" :key="index" :class="'mobile-app-tabs-items ' + ( index == activeMarket ? 'app-active-tab' : '')" :to="{ name: 'MarketViews', params: { market_type: index , child_category_list: folders},}" >
            <div class="mobile-app-tabs-item">
                <div class="mobile-app-tabs-item-row">
                    <h3>{{ item.title }}</h3> 
                </div>
            </div>
        </router-link >
        <!-- به صورت دستی اضافه کردم تا سیستم قبلی خراب نشود -->
        <router-link class="mobile-app-tabs-items" to="/retail/retail-market">
            <div class="mobile-app-tabs-item">
                <div class="mobile-app-tabs-item-row">
                    <h3>بازارهای خرده فروشی</h3> 
                </div>
            </div>
        </router-link>
    </div>

    <slot v-if="is_home">
        <div class="market-grid-home">
            <!-- <router-link  :to="{ name: 'MarketList', params: { market_id: item.id , child_category_list: folders},}"> -->
                <div v-for="(item, index) in category_list" :key="index" class="market-grid-home-item" @click="getCurrentMarketTab(index, item.id)">
                    <img :src="'https://static.tgju.org/views/default/images/tgju/app/'+item.img">
                    <span>{{ item.label }}</span>
                    <p>{{ item.info }}</p>
                </div>
            <!-- </router-link> -->
        </div>
    </slot>
    <slot v-else>

        <div class="market-slider-tabs-box">
            <router-link :to="{name:'MarketViews'}" class="mobile-back-btn">
                <i class="uil uil-angle-right-b"></i>
            </router-link>
            <div class="market-slider-tabs">
                <template>
                    <v-card>
                        <v-tabs v-model="tab" center-active>
                            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                            <v-tab v-for="(item, index) in category_list" :key="index" @click="getCurrentMarketTab(index, item.id)">
                                <i :class="item.icon"></i>
                                <span>{{ item.label }}</span>
                            </v-tab>
                        </v-tabs>
                    </v-card>
                </template>
            </div>
        </div>
    </slot>


    <div v-if="!is_home" :class="'market-main-items-data ' + ( folders.length > 0 ? 'has-select' : '') ">


        <router-view ></router-view>

        <div v-if="loading && !hide_static_market">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
        <slot v-if="!loading && !hide_static_market">
            <slot v-if="market_values.length && !is_home">
                <div :class="'market-main-item ' + (market_value.id == 'breakline' ? 'breakline-active' : '')"  v-for="market_value in market_values" :key="market_value.id">
                    <slot v-if="market_value.id == 'breakline'">
                        <div class="breakline-row">
                            {{ market_value.title }}
                        </div>
                    </slot>
                    <slot v-else>
                        <router-link :class="'market-main-item-row r-'+market_value.dt+' '+(market_value.showHighLow ? market_value.dt+'-item': '')+''"  :to="{ name: 'MarketProfile', params: { name: market_value.item_id },}">
                            <slot v-if="market_value.slug.includes('crypto-')">
                                <div class="market-main-item-col-logo">
                                    <img :src="'https://static.tgju.org/images/crypto/icons/'+ market_value.slug.replace('crypto-', '') + '_' + market_value.grade.toLowerCase() + '.png'">
                                </div>
                            </slot>
                            <slot v-else-if="market_value.image">
                                <div class="market-main-item-col-logo">
                                    <img :src="'https://platform.tgju.org/files/images/'+ market_value.image">
                                </div>
                            </slot>
                            <slot v-else-if="market_value.image == null && market_value.country">
                                <div class="market-main-item-col-logo col-logo-flag">
                                    <img :src="'https://www.tgju.org/images/flags/4x3/'+ market_value.country+'.svg'">
                                </div>
                            </slot>
                            <slot v-else>
                                <div class="market-main-item-col-logo col-logo-flag">
                                    <img src="https://static.tgju.org/views/default/images/tgju-light-logo.svg">
                                </div>
                            </slot>
                            <div class="market-main-item-col item-col-right">
                                <div class="market-main-item-title">
                                    <h2 v-if="market_value.title">
                                        {{ market_value.title }}
                                    </h2>
                                    <h2 v-else-if="market_value.title_second">
                                        {{ market_value.title_second }}
                                    </h2>
                                    <h2 v-else-if="market_value.slug">
                                        {{ market_value.slug }}
                                    </h2>
                                    <h2 v-else> {{ market_value.key }}</h2>
                                </div>
                                <div class="market-main-item-time">
                                    <i class="uil uil-clock"></i>
                                    <span>{{ market_value.t }}</span>
                                </div>
                            </div>
                            <div class="market-main-item-col item-col-left">
                                <div :class="'market-main-item-price'+' '+ market_value.dt" v-if="market_value.p_irr">
                                    <span>{{ formatPrice(market_value.p) }}</span> <div class="item-price-badge">دلار</div>
                                </div>
                                <div :class="'market-main-item-price'+' '+ market_value.dt" v-else-if="market_value.p_bid">
                                    <span>{{ formatPrice(market_value.p) }}</span> <div class="item-price-badge">تقاضا</div>
                                </div>
                                <div class="market-main-item-price" v-else>
                                    <span>{{ formatPrice(market_value.p) }}
                                    </span>
                                    <div v-if="currency" class="item-price-badge">{{ currency }}</div>
                                    <div v-else-if="market_value.currency == 'usd'" class="item-price-badge">دلار</div>
                                    <div v-else-if="market_value.currency == 'eur'" class="item-price-badge">یورو</div>
                                    <div v-else-if="market_value.currency == 'gbp'" class="item-price-badge">پوند</div>
                                </div>

                                <div class="market-main-item-change change-size-irr" v-if="market_value.p_irr">
                                    {{ market_value.p_irr }} <div class="item-price-badge">ریال</div>
                                </div>
                                <div class="market-main-item-change change-size-irr" v-else-if="market_value.p_bid">
                                    {{ market_value.p_bid }} <div class="item-price-badge">عرضه</div>
                                </div>
                                <div :class="'market-main-item-change'+' '+ market_value.dt"  v-if="!market_value.p_irr && !market_value.p_bid">
                                    {{ formatPrice(market_value.d) }} ({{ market_value.dp }} %)
                                </div>
                            </div>
                            <!-- <div class="add-to-watchlist" @click.prevent="openMarketAddToWatchListModal(market_value.id)">
                                <i class="uil uil-file-plus-alt"></i>
                            </div> -->
                        </router-link>
                    </slot>
                </div>
            </slot>
            <slot v-else-if="stock_overview.length && !is_home">
                <div class="market-main-item"  v-for="(stock_value, index) in stock_overview" :key="index">
                    <router-link class="market-main-item-row" :to="{ name: 'StockProfileOverview', params: { type: stock_value.info.market,  name: stock_value.info.symbol},}">
                        <slot v-if="stock_value.info.avatar">
                            <div class="market-main-item-col-logo col-logo-flag col-logo-flag-border">
                                <img :src="stock_value.info.avatar">
                            </div>
                        </slot>
                        <slot v-else>
                            <div class="market-main-item-col-logo col-logo-flag">
                                <img src="https://static.tgju.org/views/default/images/tgju-light-logo.svg">
                            </div>
                        </slot>
                        <div class="market-main-item-col item-col-right">
                            <div class="market-main-item-title">
                                <h2 v-if="stock_value.info.title">
                                    {{ stock_value.info.title }}
                                </h2>
                                <h2 v-else-if="stock_value.info.symbol">
                                    {{ stock_value.info.symbol }}
                                </h2>
                            </div>
                            <div class="market-main-item-time">
                                <i class="uil uil-clock"></i>
                                <span>{{ stock_value.info.market_fa }}</span>
                            </div>
                        </div>
                        <div class="market-main-item-col item-col-left">
                            <div class="market-main-item-price">
                                {{ roundInt(stock_value.info.last_trade.PDrCotVal) }} <div v-if="intToString(roundInt(stock_value.info.last_trade.PDrCotVal))" class="item-price-badge" v-html="intToString(roundInt(stock_value.info.last_trade.PDrCotVal))"></div>
                            </div>

                            <div :class="'market-main-item-change ' + ( stock_value.info.last_trade.last_change < 0 ? 'low' : '' )  +  ( stock_value.info.last_trade.last_change > 0 ? 'high' : '' )">
                                {{ stock_value.info.last_trade.last_change}} ({{ stock_value.info.last_trade.last_change_percentage }} %)
                            </div>
                        </div>
                    </router-link>
                </div>  
            </slot>
            <slot v-else-if="shakhes_overview && !is_home">
                <div class="market-main-item"  v-for="(stock_value, index) in shakhes_overview" :key="index">
                    <router-link class="market-main-item-row" :to="{ name: 'StockProfileOverview', params: { type: 'index',  name: stock_value.slug},}">
                        <slot v-if="stock_value.avatar">
                            <div class="market-main-item-col-logo col-logo-flag col-logo-flag-border">
                                <img :src="stock_value.avatar">
                            </div>
                        </slot>
                        <slot v-else>
                            <div class="market-main-item-col-logo col-logo-flag">
                                <img src="https://static.tgju.org/views/default/images/tgju-light-logo.svg">
                            </div>
                        </slot>
                        <div class="market-main-item-col item-col-right">
                            <div class="market-main-item-title">
                                <h2 v-if="stock_value.title">
                                    {{ stock_value.title }}
                                </h2>
                                <h2 v-else-if="stock_value.symbol">
                                    {{ stock_value.symbol }}
                                </h2>
                            </div>
                            <div class="market-main-item-time">
                                <i class="uil uil-clock"></i>
                                <span>{{ stock_value.flow_title }}</span>
                            </div>
                        </div>
                        <div class="market-main-item-col item-col-left">
                            <div class="market-main-item-price">
                                {{ formatPrice(stock_value.value) }}
                            </div>
                            <div :class="'market-main-item-change ' + (stock_value.change.includes('-') ? 'low' : 'high')">
                                {{ result_str_replace(stock_value.change) }} ({{result_str_replace(stock_value.change_percentage)}} %)
                            </div>
                        </div>
                    </router-link>
                    <!-- <router-link class="market-main-item-row" :to="{ name: 'StockProfileOverview', params: { type: 'index',  name: stock_value.slug},}"> -->
                    <!-- </router-link> -->
                </div>  
            </slot>
            <!-- <slot v-else>
                <div class="market-main-empty"> 
                    <div class="empty-data">
                        <i class="uil uil-file"></i>
                        <span>مقداری برای نمایش وجود ندارد</span>
                    </div>
                </div>
            </slot> -->
        </slot>
    
    </div>
</div>
</template>

<script>
import marketStaticValues from "@/assets/static_market_page_value";
import static_market from "@/assets/static_market";
import vSelect2 from 'vue-select';
import moment from "moment-jalaali";
Vue.component('v-select2', vSelect2);


// این ویو برای ماژول بازار استفاده میشود
// این ویو در موبایل قرار است فقط نمایش در بیاید
export default {
    name: 'MarketView',
    data: function () {
        return {
            marketStaticValues,
            static_market,
            tab: this.$store.state.currentTabMarket,
            market_values: [],
            stock_overview: [],
            shakhes_overview: [],
            category_list: [],
            static_market_values: [],
            breakline_market_values: [],
            folders:[],
            defSelected:[],
            activeMarket: "local-markets",
            stock_list: ['stocks-bourse', 'stocks-stock', 'stocks-bond', 'stocks-future', 'stocks-option', 'stocks-fund', 'stocks-index'],
            activeSubMarket: null,
            loading: true,
            hide_static_market: false,
            desktop: false,
            is_home: true,
            is_grid_view:false,
            currency: null,
            currentMarketId: this.$route.params.market_id,
            cache_expire_time: 180,
        }
    },
    destroyed() {
        if (this.market_values.length && !this.$store.state.globalMarketModelHistory[this.currentMarketId]) {
            var firstCache = {
                'market_id' : this.currentMarketId,
                'timestamp' : Date.now(),
                'value' : this.market_values,
            }
            this.$store.commit('pushGlobalMarketModelHistory', firstCache);
        } else {
            if (this.market_values.length) {
                var updateCache = {
                    'market_id' : this.currentMarketId,
                    'timestamp' : this.$store.state.globalMarketModelHistory[this.currentMarketId].timestamp,
                    'value' : this.market_values,
                }
                this.$store.commit('pushGlobalMarketModelHistory', updateCache);
            }
        }
    },
    beforeMount() {
        if ( this.$store.state.globalMarketModelHistory[this.currentMarketId]) {
            var exp = moment(this.$store.state.globalMarketModelHistory[this.currentMarketId].timestamp);
            if (moment().diff(exp, 'minutes') < this.cache_expire_time) {
                this.market_values = this.$store.state.globalMarketModelHistory[this.currentMarketId].value;
            } else {
                this.$store.commit('updateGlobalMarketModelHistoryTimestamp', this.currentMarketId);
            }
        }
    },
    mounted() {
        this.$store.commit('setAds', false);

        this.is_home = !window.location.href.includes('list');

        if (Vue.prototype.$helpers.checkDevice('desktop')) {
            this.desktop= true;
        }else{
            this.desktop= false;
        }

        if (!this.$route.params.market_id) {
            this.$store.commit('setMarketlayer', 0);
        }

        this.activeMarket = this.$route.params.market_type;

        if (this.$store.state.currentMarketLvl == 3) {
            this.hide_static_market = true;
        }
        this.$root.$on('hide_static_market', (data) => {
            this.hide_static_market = data;
        });

        this.$root.$on('update-stock-filter', (data) => {
            if (!this.hide_static_market) {
                this.getStockOverview();
            }
        });

        this.$root.$on('update-stock-filter-flow', (data) => {
            if (!this.hide_static_market) {
                this.getStockOverview();
            }
        });

        this.category_list = static_market[this.activeMarket].items;
        var test = this.category_list.map(item => {
            return item.id 
            }).includes(this.$route.params.market_id);
        if (test) {
            this.hide_static_market = false;
        }

        static_market[this.activeMarket].items.map((value, i) => {
            if(value.id == this.$route.params.market_id) {
                this.$store.commit('setShowStaticTab', !value.show_static ?? true);
                this.currency = value.currency ?? null;
            }
        });
        
        if (!this.hide_static_market ) {
            this.static_market_values = static_market[this.activeMarket].items.map((value, i) => {
                    if(value.id == this.$route.params.market_id) {
                        return value.static_items;
                    }
                }).filter(function(item){return item;})[0];
            if (this.stock_list.includes(this.$route.params.market_id)) {
                this.getStockOverview();
            } else {
                if (!this.market_values.length) {
                    this.getMarketValue(this.arrayToString(this.static_market_values));
                } else {
                    this.loading = false;
                }
            }

        }

        if(this.$helpers.getCookie('changeListView')){
            this.is_grid_view = true;
        }

    },
    methods: {
        result_str_replace(name){
            return name.replace("-", "");
        },
        getCurrentMarketTab(tab, id) {
            this.$store.commit('setCurrentTabMarket', tab);
            this.$router.push({ name: "MarketList", params: { market_id: id , child_category_list: this.folders},} );
        },
        getPropByString(obj, propString) {
            if (!propString)
                return obj;

            var prop, props = propString.split('.');

            for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
                prop = props[i];

                var candidate = obj[prop];
                if (candidate !== undefined) {
                obj = candidate;
                } else {
                break;
                }
            }
            return obj[props[i]];
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        dynamicStockFilter: function(stock_value) {
            var result = this.$store.state.stockFilter.value;
            return this.getPropByString(stock_value, result);
        },
        changeListView(){
            this.is_grid_view = !this.is_grid_view;
            if(this.is_grid_view){
                this.$helpers.setCookie('changeListView',true,'30'); 
            }else{
                this.$helpers.setCookie('changeListView',false,'-1'); 
            }

        },
        getStockOverview() {
            this.loading = true;
            this.$helpers
            .makeRequest("GET", "/market/stock-overview?market=" + this.$route.params.market_id ,{
                    sort: this.$store.state.stockFilter.sort,
                    flow: this.$store.state.stockFilterFlow.flow
                })
            .then((api_response) => {
                if (api_response.status == 200) {
                    if (api_response.data.response.shakhes) {
                        this.shakhes_overview = api_response.data.response.detail;
                    } else {
                        this.stock_overview = api_response.data.response.detail;
                    }
                    this.loading = false;
                } 
            });
        },
        getMarketValue(ids) {
            this.loading = true;
            this.$helpers
            .makeRequest("GET", "/market/list?market_ids="+ids)
            .then((api_response) => {
                if (api_response.status == 200) {
                    this.market_values = api_response.data.response.detail;
                    this.loading = false;
                } 

                this.static_market_values = static_market[this.activeMarket].items;
                this.static_market_values.forEach((value, i) => {
                    if(value.id == this.$route.params.market_id) {
                        value.static_items.forEach((value, i) => {
                            if(value.id == 'breakline'){
                                this.breakline_market_values.push({id: i , name: value.label });
                            }
                        });
                        this.breakline_market_values.forEach((value) => {
                            this.market_values.splice(value.id, 0, {id: 'breakline', title: value.name});
                        });
                    }
                });
            });
        },

        getSelectList(select_opt) {
            this.loading = true;
            this.$helpers
            .makeRequest("GET", "/market/category" + (select_opt == 'all' ? '' : '/' + select_opt))
            .then((api_response) => {
                var hasFolder = api_response.data.response.detail.items.map(item => {
                    return item.type
                }).includes('folder')

                if (hasFolder) {
                    this.folders = api_response.data.response.detail.items;
                    this.defSelected = {id: select_opt, label: 'نمای کلی', type: 'folder'};
                    this.folders.unshift(this.defSelected);
                } 
                this.loading = false;
            });
        },

        getMarketValueSelected(value) {
            this.loading = true;
            if(value.label == 'نمای کلی'){
                if( this.static_market[this.activeMarket].items.find(item => item.id === value.id).static_items != undefined){
                    this.getMarketValue(this.arrayToString(this.static_market[this.activeMarket].items.find(item => item.id === value.id).static_items));
                }else{
                    this.market_values = [];
                    this.loading = false;
                }
            }else{
                this.$helpers
                    .makeRequest("GET", "/market/markets-by-category/" + value.id)
                    .then((api_response) => {
                        if (api_response.status == 200) {
                            this.market_values = api_response.data.response.detail.indicators.data;
                            this.loading = false;
                        }
                });
            }
        },

        setSelected(value) {
            this.getMarketValueSelected(value);
        },

        // تغییر بازار اصلی
        switchMarkets(name){
            this.activeMarket = name;
            this.activeSubMarket = null;
            this.folders = [];
            this.category_list = static_market[name].items;
            this.static_category_list = static_market[name].static_items;
            this.getMarketValue(this.arrayToString(this.static_category_list));
        },
        // انتخاب ساب تب مارکت
        getMarkets(index,id){
            this.folders = [];
            this.getSelectList(id);
            this.activeSubMarket = index;
            if( this.static_market[this.activeMarket].items[index].static_items != undefined){
                this.getMarketValue(this.arrayToString(this.static_market[this.activeMarket].items[index].static_items));
            }else{
                this.market_values = [];
            }
        },
        // تبدیل ارایه به استرینگ برای ارسال به سرور
        arrayToString(arrayData){
            if(arrayData){
                var list = "";
                arrayData.forEach((e) => {
                    list +=  e.id + ",";
                });
                return list.substring(0, list.length - 1);
            }
        },
        // این متد برای باز کردن مودال افزودن نماد به واچ لیست و هندل کال بک آن مورد استفاده قرار میگیرد
        openMarketAddToWatchListModal(symbol) {
            let callbackEvent = this.$router.currentRoute.name + '_MarketAddToWatchList_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'MarketAddToWatchList.vue',
                config: {
                    title: 'افزودن نماد به واچ لیست',
                    smallModal: true,
                    data: {
                        symbol: symbol
                    },
                }
            };
        },

        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
    computed: {
        getCentrifuge () {
            return this.$store.state.globalCentrifugeData;
        }
    },
    watch: {
        getCentrifuge (newCount, oldCount) {
            for (var i in newCount) {
                var item = newCount[i].split("|");
                if (item[0] === 'market') {
                    for (const [index, market_item] of Object.entries(this.market_values)) {
                        if (market_item.item_id == item[1]) {
                            this.market_values[index].p = item[6];
                            this.market_values[index].d = item[10];
                            this.market_values[index].dt = item[12];
                            this.market_values[index].t = item[13];
                            this.market_values[index].updated_at = item[14];
                            Vue.set(this.market_values[index], 'showHighLow', true)
                            // منطق زیر برای هندل قیمت ریال در ارز دیجیتال است
                            if (this.market_values[index].name.includes('crypto-')) {
                                for (var j in newCount) {
                                    var itemJ = newCount[j].split("|");
                                    if (itemJ[0] === 'market') {
                                        if ((this.market_values[index].name + '-irr') == itemJ[2]) {
                                            this.market_values[index].p_irr = itemJ[6];
                                        }
                                    }
                                }
                            }
                            setTimeout( () => {
                                this.market_values[index].showHighLow = false;
                            }, 5000);
                        }
                    }
                }
            }
        }
    }
}
</script>
